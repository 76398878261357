import React from "react";
import axios, { AxiosInstance } from "axios";
import { Header, Item, Label, Container, Grid } from "semantic-ui-react";
import _get from "lodash/get";
import _truncate from "lodash/truncate";
import { SearchItemCreators } from "../utils";
import { i18next } from "@translations/invenio_app_rdm/i18next";
import { MsdliveRecordTags } from "@js/invenio_app_rdm/msdlive_components/tags";

export default class RecentUploads extends React.Component {
  axios;

  constructor(props) {
    super(props);
    this.axios = axios.create({
      baseURL: `/api`,
      withCredentials: true,
      xsrfHeaderName: "x-csrftoken",
      xsrfCookieName: "csrftoken",
      headers: {
        "Content-Type": "application/vnd.inveniordm.v1+json",
        Accept: "application/vnd.inveniordm.v1+json",
      },
    });
    this.state = { hits: undefined };
  }

  componentDidMount() {
    this.search();
  }

  search = async () => {
    try {
      //api/records?q=&sort=newest&page=1&size=10
      const resp = await this.axios.get("/records", {
        params: {
          sort: "newest",
          page: 1,
          size: 3,
        },
      });
      const parsedResponse = resp.data;
      this.setState({
        hits: parsedResponse.hits.hits,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    if (this.state.hits) {
      return (
        <Container style={{ paddingTop: 50 }}>
          <Header as="h2">Recent Contributions</Header>
          {/*copied classes and div from search result page RDM renders in order for text sizes to match */}
          <div className="ui divided link relaxed items">
            {this.state.hits.map((hit) => (
              <RDMRecordResultsListItem result={hit} />
            ))}
          </div>
        </Container>
      );
    }
    return null;
  }
}

// COPIED AND EDITED FROM msdlive-rdm-app/assets/js/invenio_app_rdm/search/components.js
export const RDMRecordResultsListItem = ({ result }) => {
  const access_status_id = _get(result, "ui.access_status.id", "open");
  const access_status = _get(result, "ui.access_status.title_l10n", "Open");
  const access_status_icon = _get(result, "ui.access_status.icon", "unlock");
  const createdDate = _get(
    result,
    "ui.created_date_l10n_long",
    "No creation date found.",
  );
  const creators = result.ui.creators.creators.slice(0, 3);

  const description_stripped = _get(
    result,
    "ui.description_stripped",
    "No description",
  );

  const publicationDate = _get(
    result,
    "ui.publication_date_l10n_long",
    "No publication date found.",
  );
  const resource_type = _get(
    result,
    "ui.resource_type.title_l10n",
    "No resource type",
  );
  const subjects = _get(result, "ui.subjects", []);
  const title = _get(result, "metadata.title", "No title");
  const version = _get(result, "ui.version", null);

  //MSDLIVE CHANGE BEGIN - add msdlive metadata fields to search results
  const msdlive_sectors = _get(result, "metadata.msdlive_sectors", []);
  const msdlive_projects = _get(result, "metadata.msdlive_projects", []);
  //MSDLIVE CHANGE END

  // Derivatives
  const viewLink = `/records/${result.id}`;
  return (
    <Item>
      <Item.Content>
        <Item.Extra className="labels-actions">
          <Label size="tiny" color="blue">
            {publicationDate} ({version})
          </Label>
          {/*MSD-LIVE CHANGE rendering access status/metadata/embargo as separate tags and customized resource type for 'other'*/}
          {/*<Label size="tiny" color="grey">*/}
          {/*  {resource_type}*/}
          {/*</Label>*/}
          {/*<Label size="tiny" className={`access-status ${access_status_id}`}>*/}
          {/*  {access_status_icon && (*/}
          {/*    <i className={`icon ${access_status_icon}`} />*/}
          {/*  )}*/}
          {/*  {access_status}*/}
          {/*</Label>*/}
          <MsdliveRecordTags result={result} />
        </Item.Extra>
        <Item.Header as="h2" style={{ margin: 5 }}>
          <a href={viewLink}>{title}</a>
        </Item.Header>
        <Item className="creatibutors">
          <SearchItemCreators creators={creators} />
        </Item>
        <Item.Description>
          {_truncate(description_stripped, { length: 350 })}
        </Item.Description>
        {/*MSDLIVE CHANGE BEGIN*/}
        <Item.Meta>
          {msdlive_sectors.map((sector, index) => (
            <Label key={index} size="tiny">
              {sector.sector}
            </Label>
          ))}
          {msdlive_projects.map((proj, index) => (
            <Label key={index + "p"} size="tiny">
              {proj.name}
            </Label>
          ))}
        </Item.Meta>
        {/*MSDLIVE CHANGE END*/}
        <Item.Extra>
          {subjects.map((subject) => (
            <Label key={subject.title_l10n} size="tiny">
              {subject.title_l10n}
            </Label>
          ))}
          {createdDate && (
            <div>
              <small>
                {i18next.t("Uploaded on")} <span>{createdDate}</span>
              </small>
            </div>
          )}
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

// this is a custom MSDLIVE file that provides the tags for hits in the my uploads and search pages
import {
  Accordion,
  Button,
  Card,
  Checkbox,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Label,
  List,
  Message,
  Segment,
  Popup,
} from "semantic-ui-react";
import React, { useState } from "react";
import _get from "lodash/get";
import _head from "lodash/head";
import _upperFirst from "lodash/upperFirst";

export const getResourceType = (esResult) => {
  let typeTitle = _get(
    esResult,
    "result.ui.resource_type.title_l10n",
    "No resource type",
  );
  if (typeTitle === "Other") {
    //  get the software or publication label from the ID - yes, this is a hack
    const id = _get(esResult, "result.ui.resource_type.id", "none");
    typeTitle = `${_upperFirst(_head(id.split("-")))}/Other`;
  }
  return typeTitle;
};

export const MsdliveRecordTags = (result) => {
  const metadataOnly =
    _get(
      result,
      "result.metadata.msdlive_file_location.location_type",
      "local",
    ) === "external";
  let embargoed = _get(result, "result.access.embargo.active", false);
  const accessTootip = _get(
    result,
    "result.ui.access_status.description_l10n",
    "",
  );

  const accessRecord = _get(result, "result.access.record", "public");
  const accessFiles = _get(result, "result.access.files", "public");
  // const restricted = metadataOnly ? accessRecord === "restricted" : (accessRecord === "restricted" && accessFiles === "restricted");
  let access = "open";
  let accessLebel = "Open";
  let accessIcon = "unlock";
  if (metadataOnly && accessRecord === "restricted") {
    access = "restricted";
    accessLebel = "Restricted";
    accessIcon = "ban";
  }
  if (
    !metadataOnly &&
    (accessRecord === "restricted" || accessFiles === "restricted")
  ) {
    access = "partial";
    accessLebel = "Partially Restricted";
    accessIcon = "unlock";
    if (accessRecord === "restricted" && accessFiles === "restricted") {
      access = "restricted";
      accessLebel = "Restricted";
      accessIcon = "ban";
    }
  }
  //it's possible that embargo was set when record was restricted, but not removed when record was changed
  //to no longer be restricted (RDM bug):
  if (embargoed && metadataOnly && accessFiles === "restricted") {
    embargoed = false;
  }
  const resourceType = getResourceType(result);
  return (
    <>
      <Label size="tiny" className="neutral">
        {resourceType}
      </Label>
      <Label size="tiny" className={`access-status ${access}`}>
        <i className={`icon ${accessIcon} `} />
        {accessLebel}
      </Label>
      {metadataOnly && (
        <Label size="tiny" className={`access-status metadata-only`}>
          <i className={`icon tag`} />
          Metadata only
        </Label>
      )}
      {embargoed && (
        <Popup
          trigger={
            <Label size="tiny" className={`access-status metadata-only`}>
              <i className={`icon outline clock`} />
              Embargoed
            </Label>
          }
          content={accessTootip}
        />
      )}
    </>
  );
};
